import styled, { css } from 'styled-components';

import {
  Container as ContainerBase,
  Heading as HeadingBase,
  Text as TextBase,
  theme,
} from '@humanoids/ui';

import OrnamentDoodleSvg from '@/images/ornament-doodle.svg';

export const HeroBlock = styled.header`
  white-space: pre-line;

  div:has(> &) {
    background-color: transparent;
  }
`;

interface StyledBackgroundProps {
  alternate: boolean;
}

export const Background = styled.div<StyledBackgroundProps>(
  ({ alternate, theme }) => css`
    background-color: ${alternate
      ? theme.backgroundColor.secondary
      : theme.backgroundColor.primary};

    &:not(:has(img)):not(:has(video)) {
      padding-bottom: ${alternate ? 'calc(2 * var(--spacing))' : '0'};
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      background: ${alternate
        ? `linear-gradient(${theme.backgroundColor.secondary} 60%, ${theme.backgroundColor.primary} 60%) `
        : theme.backgroundColor.primary};

      &:not(:has(img)):not(:has(video)) {
        background: ${alternate
          ? theme.backgroundColor.secondary
          : theme.backgroundColor.primary};

        padding-bottom: ${alternate ? 'calc(2 * var(--spacing))' : '0'};
      }
    }
  `
);

export const Container = styled(ContainerBase)(
  ({ theme }) => css`
    align-content: start;
    display: grid;
    gap: 24px;
    grid-auto-flow: row;
    grid-template-rows: auto min-content;
    justify-content: center;
    justify-items: center;
    padding: calc(2 * var(--spacing)) var(--spacing) 0;
    position: relative;
    white-space: pre-line;

    &:not(:has(img)):not(:has(video)) {
      grid-template-rows: auto min-content;
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      gap: 48px;
      overflow: hidden;
    }

    @media (min-width: ${theme.breakpoints.large}) {
      overflow: unset;
    }
  `
);

export const Heading = styled(HeadingBase)<{ alternate: boolean }>(
  ({ alternate, theme }) => css`
    color: ${alternate && theme.textColor.contrast};
    padding: 0 var(--spacing);

    img {
      height: 24px;
    }

    em {
      color: ${theme.brandColors.primary.dark};
      position: relative;
      text-decoration: none;

      &:after {
        content: url('${OrnamentDoodleSvg}');
        display: block;
        height: 16px;
        left: 0;
        margin-left: 40px;
        margin-top: -45px;
        position: absolute;
        top: 0;
        width: 45px;
      }
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      margin-bottom: 0;

      img {
        height: 48px;
      }

      em {
        &:after {
          height: 32px;
          margin-left: 55px;
          margin-top: -65px;
          width: 80px;
        }
      }
    }
  `
);

export const Text = styled(TextBase)<{ alternate: boolean }>(
  ({ alternate, theme }) => css`
    align-self: center;
    color: ${alternate && theme.textColor.contrast};

    @media (min-width: ${theme.breakpoints.medium}) {
      padding-left: calc(var(--spacing) * 2);
      padding-right: calc(var(--spacing) * 2);
    }

    @media (min-width: ${theme.breakpoints.large}) {
      padding-left: calc(var(--spacing) * 4);
      padding-right: calc(var(--spacing) * 4);
    }
  `
);

export const Image = styled(ContainerBase)(
  ({ theme }) => css`
    height: 280px;
    margin: 0 calc(var(--spacing) * -1);
    padding: 0;
    position: relative;
    width: 100vw;

    @media (min-width: ${theme.breakpoints.medium}) {
      height: 604px;
      margin: 0;
      max-width: calc(1200px - calc(2 * var(--spacing)));
    }

    img {
      object-fit: cover;
    }
  `
);

export const imageMediaCondition = `
  (max-width: ${theme.breakpoints.medium}) 100vw,
  60vw
`;

export const Video = styled(ContainerBase)(
  ({ theme }) => css`
    height: auto;
    margin: 0 calc(var(--spacing) * -1);
    padding: 0;
    width: 100vw;

    @media (min-width: ${theme.breakpoints.medium}) {
      margin: 0;
      padding: 0 var(--spacing);
    }

    video {
      width: 100%;
    }
  `
);

export const LineDoodleImage = styled.div(
  ({ theme }) => css`
    display: none;

    @media (min-width: ${theme.breakpoints.medium}) {
      bottom: 10%;
      display: block;
      height: 16px;
      left: calc(var(--spacing) * -1);
      position: absolute;
      width: 124px;
    }
  `
);

export const StarsImage = styled.div(
  ({ theme }) => css`
    display: none;

    @media (min-width: ${theme.breakpoints.medium}) {
      display: block;
      height: 212px;
      position: absolute;
      right: 140px;
      top: 230px;
      width: 250px;
    }
  `
);

export const DotsTriangleImage = styled.div`
  display: none;

  @media (min-width: ${theme.breakpoints.medium}) {
    display: block;
    height: 75px;
    position: absolute;
    right: -37px;
    top: 35%;
    width: 81px;
  }
`;
