import { useEffect, useState, useRef } from 'react';

import { useEventListener } from '@/hooks/use-event-listener';

import * as Styled from './HeroTextSwap.styled';

interface HeroTextSwapProps {
  intro?: string;
  outro?: string;
  swapWords: string[];
}

export const HeroTextSwap = ({
  intro,
  outro,
  swapWords,
}: HeroTextSwapProps) => {
  const [count, setCount] = useState(1);
  const [delta, setDelta] = useState(0);
  const [offset, setOffset] = useState(0);

  const offsetRef = useRef<HTMLSpanElement>();
  const swapRef = useRef<HTMLSpanElement>();

  useEventListener(
    'animationiteration',
    () => {
      swapRef.current.innerText = swapWords[count % swapWords.length];

      setCount((count) => count + 1);
      setDelta(() => swapRef.current?.getBoundingClientRect().width - offset);
    },
    swapRef.current
  );

  useEffect(() => {
    setOffset(offsetRef.current?.getBoundingClientRect().width);
  }, []);

  return (
    <Styled.Heading primary>
      <Styled.Intro>{intro}</Styled.Intro>
      {count === 1 && (
        <Styled.Placeholder ref={offsetRef}>{swapWords[0]}</Styled.Placeholder>
      )}
      <Styled.Swap ref={swapRef} />
      <Styled.Outro offset={offset} delta={delta}>
        {outro}
      </Styled.Outro>
    </Styled.Heading>
  );
};
