import { useEffect, useRef } from 'react';

type FunctionType = (...args: unknown[]) => void;

export const useEventListener = (
  eventType: string,
  callback: FunctionType,
  element: HTMLElement
) => {
  const savedCallback = useRef<FunctionType>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!element) {
      return;
    }

    const listener = (event: Event) => savedCallback.current(event);

    element.addEventListener(eventType, listener);

    return () => {
      element.removeEventListener(eventType, listener);
    };
  }, [eventType, element]);
};
