import styled, { css } from 'styled-components';

import { Heading as HeadingBase } from '@humanoids/ui';

export const Heading = styled(HeadingBase)(
  ({ theme }) => css`
    display: grid;
    font-size: 32px;
    font-weight: 900;
    grid-template-columns: repeat(3, min-content);
    grid-template-rows: repeat(2, min-content);
    justify-items: start;
    line-height: 40px;
    overflow: hidden;
    padding: 0 0 24px;
    position: relative;
    width: 100%;

    @media (min-width: ${theme.breakpoints.medium}) {
      font-size: 64px;
      line-height: 80px;
      padding: 48px 32px 64px;
    }
  `
);

export const Intro = styled.div`
  grid-column: 1 / span 3;
`;

export const Swap = styled.span(
  ({ theme }) => css`
    animation: wordSwap 2s
      linear(
        0,
        0.6832 7.89%,
        0.9171 11.07%,
        1.0251,
        1.1058 14.9%,
        1.1619 16.86%,
        1.1945 18.91%,
        1.2024 20.02%,
        1.2043 21.18%,
        1.1907,
        1.1598 26.27%,
        1.0604 32.59%,
        1.0172 35.84%,
        0.9839 39.49%,
        0.967 43.26%,
        0.9639 45.77%,
        0.9661 48.59%,
        0.9963 60.54%,
        1.0054 67.42%,
        1
      )
      infinite;

    /**
     * TODO: Once WebKit properly supports linear functions 
     * this should be removed.
     */
    @supports (hanging-punctuation: first) and (-webkit-appearance: none) {
      animation: wordSwap 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
    }

    color: ${theme.brandColors.primary.dark};
    display: inline-block;
    opacity: 0;
    overflow: hidden;
    white-space: nowrap;

    @keyframes wordSwap {
      10% {
        opacity: 0;
        transform: translateY(-100%);
      }
      30% {
        opacity: 1;
        transform: translateY(0px);
      }
      50% {
        opacity: 1;
        transform: translateY(0px);
      }
      90% {
        opacity: 1;
        transform: translateY(0px);
      }
      100% {
        opacity: 0;
        transform: translateY(100%);
      }
    }
  `
);

export const Placeholder = styled.span(
  ({ theme }) => css`
    color: ${theme.brandColors.primary.dark};
    display: inline-block;
    margin-right: 24px;
    white-space: nowrap;

    @media (min-width: ${theme.breakpoints.medium}) {
      margin-right: 0;
    }
  `
);

interface StyledOutroProps {
  delta: number;
  offset: number;
}

export const Outro = styled.span.withConfig({
  shouldForwardProp: (prop) => !['delta', 'offset'].includes(prop),
})<StyledOutroProps>(
  ({ delta, offset, theme }) => css`
    bottom: 24px;
    left: calc(${offset}px + var(--spacing));
    position: ${offset > 0 ? 'absolute' : 'static'};
    transition: transform 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55) 2s;
    transition: transform 0.2s cubic-bezier(0.31, 1.28, 0.32, 1.275);
    transform: translateX(calc(${delta}px - 16px));

    @media (min-width: ${theme.breakpoints.medium}) {
      bottom: 64px;
      transform: translateX(calc(${delta}px + 16px));
    }
  `
);
